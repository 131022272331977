import React from 'react'
import '../styles/main.scss'

const Layout = ({ children }) => {
  return (
    <div className="c-content u-pd-vt-m u-pd-xl@main">
      {children}
    </div>
  )
}

export default Layout
